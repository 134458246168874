<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.order_ID')" prop="rechargeId">
            <el-input
              :maxlength="32"
              v-model.trim="formInline.rechargeId"
              placeholder="请输入订单ID"
            ></el-input>
          </el-form-item>
          <el-form-item label="会员ID" prop="memberId">
            <el-input
              :maxlength="32"
              v-model.trim="formInline.memberId"
              placeholder="请输入会员ID"
            ></el-input>
          </el-form-item>
          <el-form-item label="充值手机号" prop="mobile">
            <el-input
              :maxlength="11"
              v-model.trim="formInline.mobile"
              placeholder="请输入充值手机号"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_channels')" prop="payChannel">
            <el-select v-model.trim="formInline.payChannel">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.channelTypeDesc"
                :value="value.channelType"
                :key="value.channelType"
                v-for="value in channelList"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.time_slot')">
            <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"
                            @change="startPayTime"> </el-date-picker> 至
            <el-date-picker v-model="date2"
                            type="datetime"
                            placeholder="选择日期"
                            @change="endPayTime"> </el-date-picker> -->
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              style="margin-right: 10px"
              :defalutDate="defalutDate"
            />
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button type="primary" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
            <exportFile
              style="display: inline-block; margin-left: 10px"
              v-if="$route.meta.authority.button.export"
              :exportData="exportData"
            ></exportFile>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, setIndex } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "rechargeOrder",
  data() {
    return {
      defalutDate: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      payType: [],
      areaList: [],
      areaList2: [],
      parkType: [],
      tableData: [],
      payTypeList: [],
      channelList: [],
      tableCols: [
        {
          prop: "rechargeId",
          label: this.$t("list.order_ID"),
          width: "",
        },
        {
          prop: "memberId",
          label: "会员ID",
          width: "",
        },
        {
          prop: "money",
          label: "充值金额",
          width: "",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        //		{
        //          prop: 'payTypeDesc',
        //          label: '支付方式',
        //          width: ''
        //        },
        {
          prop: "payChannelDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
      ],
      formInline: {
        rechargeId: "",
        memberId: "",
        mobile: "",
        // payType: '',
        payChannel: "",
        startTime: "",
        endTime: "",
        name: "充值记录",
      },
      exportData: {},
    };
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payment/payTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 设置开始时间
    startPayTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    // 设置结束时间
    endPayTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    // 清空
    resetForm() {
      this.formInline = {
        rechargeId: "",
        memberId: "",
        mobile: "",
        // payType: '',
        payChannel: "",
        startTime: "",
        endTime: "",
      };
      this.$refs.timeRangePicker.resetTime();
    },
    // 分页触发
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 判断搜索时间长度限制
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 搜索
    searchData() {
      let flag = this.showLog();
      if (!flag) return;
      let opt = {
        method: "get",
        url: "/acb/2.0/recharge/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          rechargeId: this.formInline.rechargeId,
          memberId: this.formInline.memberId,
          mobile: this.formInline.mobile,
          // payType: this.formInline.payType,
          payChannel: this.formInline.payChannel,
          startPayTime: this.formInline.startTime,
          endPayTime: this.formInline.endTime,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      if (flag) {
        this.loading = true;
        this.$request(opt);
      }
    },
  },
  components: {
    exportFile,
    timeRangePick,
  },
  created() {
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    // this.getPay();
    this.getChannelList();

    // 导出数据
    this.exportData = {
      url: "/acb/2.0/recharge/exportList",
      methods: "post",
      data: this.formInline,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      border 1px solid #0099FA
      border-radius 4px
      color #0099FA
      padding 8px 13px
      cursor pointer
      .iconfont
        margin-right 14px
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
